import { template as template_bd3b670d5bb74a8f8f61f27e85e7edcd } from "@ember/template-compiler";
const WelcomeHeader = template_bd3b670d5bb74a8f8f61f27e85e7edcd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
