import { template as template_cfa5bed9a71646f9946bc60482776a98 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_cfa5bed9a71646f9946bc60482776a98(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
