import { template as template_7b657520103d4b8f8f5564da801b49c2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7b657520103d4b8f8f5564da801b49c2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
